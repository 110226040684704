import React, { useRef, useState } from "react";
import axios from "axios";

// import send icon from react-icons/fa
import { FaPaperPlane, FaUpload } from "react-icons/fa";


const ChatInput = ({ inputMessage, setInputMessage, sendMessage }) => {

  const handleFileChange = async(e) => {

      // const prompt = "want to do a question and answer session on the following text:\n";
      const prompt = "";
      const endPoint = "upload";
      // hit api for file upload

      let formData = new FormData();    //formdata object
        formData.append('file', e.target.files[0]);
        formData.append('remark', "input file for qna.");

        const config = {     
            headers: { 'content-type': 'multipart/form-data' }
        }
      const response = await axios.post(`https://ai-gpt-test.jupitice.com/api/${endPoint}/`, formData, config);

      sendMessage(prompt + response.data.fileContent);
  }


  return (
    <div className="chat-input">
      <textarea
        placeholder="Type a message"
        value={inputMessage}
        onChange={(e) => setInputMessage(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            if (inputMessage) {
              sendMessage();
            }
          }          
        }}
      />
      {/* <input type='file' id='file' style={{display: 'flex'}} onChange={handleFileChange}/> */}
      {/* <button onClick={handleButtonClick}>Upload</button> */}
      <div className="inputWrapper">
        <span ><FaUpload size={"lg"}/></span>
        <input className="fileInput" type="file" onChange={handleFileChange}/>
      </div>
      <button onClick={sendMessage} disabled={!inputMessage}>
        <FaPaperPlane/>
      </button>
    </div>
  );
};

export default ChatInput;
