import React from "react";

const NavBarCopy = () => {

    return (
        <>
            <nav id="navbarMain" className="navbar navbar-expand-lg navbar-dark fixed-top">
                <div className="container">
                    <a className="navbar-brand fw-bold" href="/">
                        <img src="https://jupitice-website.s3.ap-south-1.amazonaws.com/images/logo-without-tagline.svg" alt="Image"/>
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">                       
                            <li className="nav-item"><button className="btn btn-primary" type="submit">Login</button></li>
                            {/* <li className="nav-item"><a className="nav-link">Link</a></li> */}
                        </ul>
                        
                    </div>
                </div>
            </nav>
        </>
    );
}

export default NavBarCopy;