import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import TextEditor from "../components/textEditor";
import NavBar from "../components/NavBar";
import axios from "axios";
import FooterContent from "../components/FooterContent";
import FadeLoader from "react-spinners/FadeLoader";

const GenericPage = (props) => {

    var [text, setText] = useState("");
    const [textInput, setTextInput] = useState("");
    const [loader, setLoader] = useState("none");
    let [color, setColor] = useState("#000");

    const currentURL = window.location.href;

    const headings = currentURL.split("?");
    const headingArr = headings[1].split(",");
    const MainHeading = headingArr[0].replaceAll("_", " ");
    const SecondHeading = headingArr[1].replaceAll("_", " ");

    var content = "";
    var prompt = "";
    var enterText = "text";
    var endPoint = "summarize";

    if (MainHeading == "Offense Analyzer") {

        content = "Empowering Justice. Unveiling Truth. Crime Identifier: Unmasking the Culprits.";
        prompt = "get offense type form the following text:\n";
    
    } else if (MainHeading == "Draft a Complaint") {

        content = "Raise Your Voice. Demand Justice. Complaint Drafting: Empowering Your Grievances.";
        prompt = "Draft a police complaint for the following situation:\n";
	    enterText = "Brief Facts";
        endPoint = "generic";
    
    } else if (MainHeading == "Translate Text") {

        content = "Bridging Language Barriers. Seamless Communication. Translation Product: Unlocking Global Connections.";
        prompt = "Translate the following text into ";
    
    } else if (MainHeading == "Document Generator") {

        content = "Efficiently Generate Legally Binding Documents with Ease";
        prompt = "Generate a document for ";
        endPoint = "generic"
    
    } else if (MainHeading == "Case Similarity Identifier") {

        content = "Effortlessly Generate Tailored Contract Clauses for Your Legal Documents";
        prompt = "Compare the two documents and generate confidence score for the same:";
        // endPoint = "generic"
    
    } else if (MainHeading == "Case Similarity Identifier") {

        content = "Effortlessly Generate Tailored Contract Clauses for Your Legal Documents";
        prompt = "Compare the two documents and generate confidence score for the same:";
        endPoint = "generic"
    
    }  else if (MainHeading == "Complaint Analyzer") {

        content = "Effortlessly Generate Tailored Contract Clauses for Your Legal Documents";
        prompt = "analyze consumer complaint provided below and provide a review on the basis of complaint validation, explainability and seniment analysis\ninstructions: review can be in the list (excellent, good, average, bad), provide only review nothing else, it should be a one liner review\nComplaint:";
        // endPoint = "generic"
    
    } else if (MainHeading == "Legal Research Writing") {

        content = "Empower Your Legal Writing with Comprehensive Legal Research";
        prompt = "";
        endPoint = "generic"
    
    } else if (MainHeading == "Draft a Complaint") {

        content = "Empower Your Legal Writing with Comprehensive Legal Research";
        prompt = "Generate a complaint based on the following facts:\n";
        endPoint = "generic"
    
    } else if (MainHeading == "Legal Entity Identifier") {

        content = "Securely Identify and Verify Legal Entities with our LEI Solutions";
        prompt = "";
    
    } else if (MainHeading == "Cause List Generation") {

        content = "Securely Identify and Verify Legal Entities with our LEI Solutions";
        prompt = "generate cause list on the basis of following cases, instructions there are only two courtrooms:\n";
        endPoint = "generic"
    
    } else if (MainHeading == "Case Record") {

        content = "Securely Identify and Verify Legal Entities with our LEI Solutions";
        prompt = "get case record of the following case:\ninstruction: keep it precise and keep important information like victim information, case number";
        endPoint = "generic"
    
    } else if (MainHeading == "Case Diagnostics") {

        content = "Securely Identify and Verify Legal Entities with our LEI Solutions";
        prompt = "Calculate the winning probability of the following case INSTRUCTION if no probability is found, list down the remarks precisely:\n";
        endPoint = "generic"
    
    } else if (MainHeading == "Argument Miner") {

        content = "Securely Identify and Verify Legal Entities with our LEI Solutions";
        prompt = "extract arguements for the paprties involved in short form from the below mentioned case\n";
        endPoint = "generic"
    
    } else if (MainHeading == "Judgement Finder") {

        content = "Securely Identify and Verify Legal Entities with our LEI Solutions";
        prompt = "list some case judgments on ";
        endPoint = "generic"
    
    } else if (MainHeading == "Bench Constitutions") {

        content = "Securely Identify and Verify Legal Entities with our LEI Solutions";
        prompt = "list down the bench constitution in points for the following cases according to the complexity, severity of the case\n";
        endPoint = "generic"
    
    } else if (MainHeading == "Police Complaint Generator") {

        content = "Securely Identify and Verify Legal Entities with our LEI Solutions";
        prompt = "draft a police complaint based on the following facts:\n";
        endPoint = "generic"
    
    } else if (MainHeading == "Notice Generation") {

        content = "Securely Identify and Verify Legal Entities with our LEI Solutions";
        prompt = "instructions: it is a legal notice, not a letter do not create a letter formatcreate a notice template on behalf of admin staff of rera to the builder about the ";
        endPoint = "generic"
    
    }  else if (MainHeading == "Clause Generator") {

        content = "Securely Identify and Verify Legal Entities with our LEI Solutions";
        prompt = "generate a clause for ";
        endPoint = "generic"
    
    }  else if (MainHeading == "Clause Analysis") {

        content = "Securely Identify and Verify Legal Entities with our LEI Solutions";
        prompt = "Do clause analysis on the below text\n";
        endPoint = "generic"
    
    } else {

        content = "Empowering Legal Excellence. Streamlined Solutions. Legal Product: Your Path to Success.";
    }

    const handleSubmit = async(e) => {

        e.preventDefault();

        setLoader("flex");

        let formData = new FormData();    //formdata object
        formData.append('text', JSON.stringify(prompt + textInput));

        const config = {     
            headers: { 'content-type': 'multipart/form-data' }
        }

        try {
            const response = await axios.post(`https://ai-gpt-test.jupitice.com/api/${endPoint}/`, formData, config);
            setText(response.data.summary);
            setLoader("none");
        } catch (error) {
            console.error("Error fetching chats:", error);
            setText("Something happend at the server.");
            setLoader("none");
        }
      

    }

    const hanldeChange = (event) => {

        event.preventDefault();
        setTextInput(event.target.value)

    }

    return (
        <>
        <NavBar/>
            <section className="hero-container pb-5 pt-5 overflow-hidden">
                <div className="container mt-5 mb-5">
                    <div className="row pt-3">
                        <div className="col-lg-6">
                            <div className="card widget">
                                <div className="card-body">
                                    <div className="card-header">
                                        <span className="icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" height="0.8em" viewBox="0 0 448 512">
                                                <path d="M96 0C43 0 0 43 0 96V416c0 53 43 96 96 96H384h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V384c17.7 0 32-14.3 32-32V32c0-17.7-14.3-32-32-32H384 96zm0 384H352v64H96c-17.7 0-32-14.3-32-32s14.3-32 32-32zm32-240c0-8.8 7.2-16 16-16H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16zm16 48H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
                                            </svg>
                                        </span>
                                        <span className="text">{MainHeading}</span>
                                    </div>
                                   
                                    <div className="iconText grey">
                                        {content}
                                    </div>

                                    <div className="iconText">
                                        <div className="question">Please enter your {enterText} below <span className="asstric">*</span></div>
                                    </div>
                                    <form className="form-group" onSubmit={handleSubmit}>
                                        {/* <input className="form-control" type="text" placeholder="Type in your text here.."/> */}
                                        <textarea className="form-control rounded-2 z-depth-1 grey" rows={5} cols={50} placeholder="Type in your text here.." onChange={hanldeChange}></textarea>

                                        <button type="submit" className="btn btn-primary">Generate Text</button>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="card widget">
                                <div className="card-body">
                                <div className="card-header">
                                        <span className="icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" height="0.8em" viewBox="0 0 448 512">
                                                <path d="M96 0C43 0 0 43 0 96V416c0 53 43 96 96 96H384h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V384c17.7 0 32-14.3 32-32V32c0-17.7-14.3-32-32-32H384 96zm0 384H352v64H96c-17.7 0-32-14.3-32-32s14.3-32 32-32zm32-240c0-8.8 7.2-16 16-16H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16zm16 48H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
                                            </svg>
                                        </span>
                                        <span className="text">{SecondHeading}</span>
                                    </div>
                                   
                                    <div className="iconText grey">
                                    Our smart editor allows you to easily customize your content. Change the order of sentences, add, edit or remove information to match your style.
                                    </div>
                                    <div id="overlay" style={{display: loader}}>
                                        <div class="w-100 d-flex justify-content-center align-items-center">
                                            <div className="sweet-loading">
                                                <FadeLoader
                                                    color={color}
                                                    loading={true}
                                                    size={20}
                                                    aria-label="Loading Spinner"
                                                    data-testid="loader"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <TextEditor value={text} />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            
            {/* <FooterContent /> */}
        </>
    );
}

export default GenericPage;
