import React from "react";

const FooterContent = () => {

    return (
        <>
            {/* <!--Footer--> */}
            <footer className="footer mt-5">
                <div className="container">
                    <div className="row">
                        {/* <!-- end col-12 --> */}
                        <div className="col-lg-3 col-md-6">
                            <figure className="logo">
                                <img src="https://jupitice-website.s3.ap-south-1.amazonaws.com/images/logo-without-tagline.svg" alt="Image"/>
                            </figure>
                            <p><b>Corporate Office</b></p>
                            <address>
                                Plot No.14,
                                Rajiv Gandhi Chandigarh Technology Park,
                                Adjoining Infosys,
                                Chandigarh, India – 160101

                            </address>
                            <p><b>Regional Office:</b></p>
                            <address>
                                Unit No. 421, 4th Floor,<br/>
                                Time Tower, M.G. Road,<br/>
                                Gurugram, Haryana, India – 122002

                            </address>

                            <p><b>Registered Office Address:</b></p>
                            <address>
                                Plot No.14,
                                Rajiv Gandhi Chandigarh Technology Park,
                                Adjoining Infosys,
                                Chandigarh, India – 160101

                            </address>
                            <p className="footer-contact"><b>CIN:</b>  U74999CH2019PTC042438 <br/>
                                <a href="mailto:enquiry@jupitice.com" target="_blank"><b>Email:</b>
                                enquiry@jupitice.com</a>
                                <br />
                                <a href="tel:+91-78884 90649" target="_blank"> <b>Phone:</b> +91-78884 90649</a>
                            </p>
                        </div>
                        {/* <!-- end col-5 --> */}
                        <div className="col-lg-3 col-md-6">
                            <h4>Company</h4>
                            <ul>
                                <li><a href="https://jupitice.com/about-us.php">About us</a></li>
                                <li><a href="https://jupitice.com/founder.php">Founder</a></li>
                                <li><a href="https://jupitice.com/co-founder.php">Co-Founder</a></li>
                                <li><a href="https://jupitice.com/media-coverage.php">Jupitice in Media</a></li>
                                <li><a href="https://jupitice.com/events.php">Events</a></li>
                                <li><a href="https://jupitice.com/contact-us.php">Contact Us</a></li>
                                
                                
                                
                                <li> <a href="https://career.jupitice.com/" target="_blank">Careers</a > </li>
                            </ul>

                            <h4>Jupitice Justice Foundation </h4>
                            <p><i>(Incorporated U/S 8 of the Indian Companies Act 2013)</i></p>
                            <ul>
                                <li><a href="jupitice-justice-foundation.php">Jupitice Justice Foundation</a></li>
                            </ul>



                        </div>

                        <div className="col-lg-3 col-md-6">
                            <h4>Buyer Centre</h4>
                            <ul>
                                <li><a href="https://jupitice.com/buyers-multiple-options.php">Buyers Multiple Options</a></li>
                                <li><a href="https://jupitice.com/buyers-protection.php">Buyer Protection</a></li>
                                <li><a href="https://jupitice.com/jupitice-guarantee.php">Jupitice Guarantee</a></li>
                                <li> <a href="https://jupitice.com/business-model.php" target="_blank">Business Model</a > </li>
                                <li><a href="https://jupitice.com/pricing-format.php">Pricing Format</a></li>
                                <li><a href="https://jupitice.com/marketplace-format.php"> Marketplace Format</a></li>
                                <li><a href="https://jupitice.com/jupitice-market-coverage.php">Jupitice Market Coverage</a></li>
                                <li><a href="https://jupitice.com/escrow-services.php">Escrow Services</a></li>
                            </ul>

                            <h4>Seller Centre</h4>
                            <ul>
                                <li><a href="https://jupitice.com/adr-marketplace.php"> How It Works for ADR Professionals</a></li>
                                <li><a href="https://jupitice.com/seller-protection.php">Seller Protection</a></li>
                                <li><a href="https://jupitice.com/jupitice-guarantee.php">Jupitice Guarantee</a></li>
                                <li> <a href="https://jupitice.com/pricing-format.php"> Pricing Format</a > </li>
                                <li><a href="https://jupitice.com/escrow-services.php">Escrow Services</a></li>
                                <li><a href="https://jupitice.com/jupitice-market-coverage.php">   Jupitice Market Coverage</a></li>
                            </ul>  


                        </div>
                        {/* <!-- end col-4 --> */}
                        <div className="col-lg-3 col-md-6">
                            <h4>Our Support</h4>
                            <ul>    
                                <li><a href="https://jupitice.com/faqs.php">FAQs</a></li>
                                <li><a href="https://jupitice.com/contact-us.php">Customer Service</a></li>
                                <li><a href="https://jupitice.com/contact-us.php">Request a Demo</a></li>
                                
                            </ul>

                            <h4>Impact</h4>
                            <ul>
                                <li><a href="https://jupitice.com/social-impact.php">Social</a></li>
                                <li><a href="https://jupitice.com/economic-impact.php">Economic</a></li>
                                <li><a href="https://jupitice.com/environmental-impact.php">Environmental</a></li>
                            </ul>

                            

                        </div>
                        {/* <!-- end col-3 --> */}
                        <div className="col-12">
                            <div className="sub-footer">
                                <span>© 2023 Jupitice</span>
                                <ul>
                                    <li><a href="https://jupitice.com/terms-of-services.php"> Terms of Services</a></li>
                                    <li><a href="https://jupitice.com/privacy-policy.php">Privacy Policy</a></li>
                                    <li><a href="https://jupitice.com/cookie-policy.php">Cookie Policy</a></li>
                                </ul>
                                
                            </div>
                            {/* <!-- end sub-footer --> */}
                        </div>
                        {/* <!-- end col-12 --> */}
                    </div>
                    {/* <!-- end row --> */}
                </div>
                {/* <!-- end container --> */}
            </footer>
        </>
    );
}

export default FooterContent;