import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import ProductPage from "./Pages/ProductPage";
import "./App.css";
import "./styles.scss";
import SummaryPage from "./Pages/SummaryPage";
import GenericPage from "./Pages/GenericPage";
import IndexPage from "./Pages/IndexPage";
import ErrorPage from "./Pages/ErrorPage";
import TestPage from "./Pages/TestPage";
import QNAPage from "./Pages/QNAPage";
import FooterContent from "./components/FooterContent";
import '../src/css/chatui.css';

function App() {
  return (
    <>
      <Switch>
        <Route path="/" exact component={IndexPage} />
        <Route path="/products" exact component={ProductPage} />
        <Route path="/summarization" exact component={SummaryPage} />
        <Route path="/offense-identifier" exact component={GenericPage} />
        <Route path="/draft-complaint" exact component={GenericPage} />
        <Route path="/translation" exact component={GenericPage} />
        <Route path="/document-review" exact component={GenericPage} />
        <Route path="/document-generator" exact component={GenericPage} />
        <Route path="/case-law-finder" exact component={GenericPage} />
        <Route path="/clause-generator" exact component={GenericPage} />
        <Route path="/clause-analysis" exact component={GenericPage} />
        <Route path="/legal-research-writing" exact component={GenericPage} />
        <Route path="/legal-analysis" exact component={GenericPage} />
        <Route path="/law-research" exact component={GenericPage} />
        <Route path="/explain-clause" exact component={GenericPage} />
        <Route path="/judgement-builder" exact component={GenericPage} />
        <Route path="/brief-drafting" exact component={GenericPage} />
        <Route path="/case-summarized-record" exact component={GenericPage} />
        <Route path="/bi-info-extractor" exact component={GenericPage} />
        <Route path="/complaint-analyzer" exact component={GenericPage} />
        <Route path="/missing-info-identifer" exact component={GenericPage} />
        <Route path="/case-sim-identifier" exact component={GenericPage} />
        <Route path="/notice-generator" exact component={GenericPage} />
        <Route path="/case-summarized-record" exact component={GenericPage} />
        <Route path="/case-list-generation" exact component={GenericPage} />
        <Route path="/case-record" exact component={GenericPage} />
        <Route path="/case-diagnostics" exact component={GenericPage} />
        <Route path="/argument-miner" exact component={GenericPage} />
        <Route path="/judge-finder" exact component={GenericPage} />
        <Route path="/online-scrutiny" exact component={GenericPage} />
        <Route path="/bench-constitutions" exact component={GenericPage} />
        <Route path="/complaint-generator" exact component={GenericPage} />
        <Route path="/judgement-finder" exact component={GenericPage} />
        <Route path="/reply-petition" exact component={GenericPage} />
        <Route path="/document-redlining" exact component={GenericPage} />
        <Route path="/ler" exact component={QNAPage} />
        <Route path="/case-qna" exact component={QNAPage} />
        <Route path="/document-qna" exact component={QNAPage} />
        <Route path="/test" exact component={TestPage} />
        <Route path="*" component={ErrorPage} />
      </Switch>

      <FooterContent/>

    </>
  );
}

export default App;
