class Product {
  id: string;
  name: string;
  link: string;
  info: string;
  img: string;
  constructor(name: string, id: string, link: string, info: string, img: string) {
    this.name = name;
    this.id = id;
    this.link = link;
    this.info = info;
    this.img = img;
  }
}

export default Product;
