import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { addProduct, updateProduct } from "../app/productSlice";
import { v4 as uuidv4 } from "uuid";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { BsFillCursorFill } from "react-icons/bs";

type ProductFormData = {
  name: string;
  email: string;
  telephone: string;
  link: string;
  info: string;
  img: string;
};
const schema = yup
  .object({
    name: yup.string().required(),
    email: yup.string().email().required(),
    telephone: yup.string().required(),
    link: yup.string().required(),
    info: yup.string().required(),
    img: yup.string().required(),
  })
  .required();

interface NewProductProps {
  id: string;
}
const NewProduct: React.FC<NewProductProps> = ({ id }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<ProductFormData>({
    resolver: yupResolver(schema),
  });
  console.log(id);
  const history = useHistory();
  const dispatch = useAppDispatch();

  const productData = useAppSelector((state) =>
    state.product.productList.find((product) => product.id === id)
  );

  setValue("name", productData?.name || "");
  setValue("link", productData?.link || "");
  setValue("info", productData?.info || "");
  setValue("img", productData?.img || "");

  const onSubmit = (data: ProductFormData) => {
    const { name, link, info, img } = data;

    if (id) {
      editProduct(name, link, info, img );
      return;
    }
    dispatch(addProduct({ name, id: uuidv4(), link, info, img }));
    history.push("/");
  };

  const editProduct = (name: string, link: string, info: string, img: string) => {
    dispatch(updateProduct({ name, id, link, info, img }));
    history.push("/");
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="bg-white  p-4 px-4 text-sm ">
        <div className="md:col-span-5">
          <label htmlFor="full_name" className="text-left">
            Full Name
          </label>

          <input
            type="text"
            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
            placeholder="Full Name"
            {...register("name")}
          />
          <p className="mt-2 text-sm text-red-600">{errors.name?.message}</p>
        </div>

        <div className="mt-3">
          <label htmlFor="link">Link</label>
          <input
            type="text"
            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
            placeholder="250 788 841 494"
            {...register("link")}
          />
          <p className="mt-2 text-sm text-red-600">
            {errors.link?.message}
          </p>
        </div>

        
        <div className="mt-3">
          <label htmlFor="info">Info</label>
          <input
            type="text"
            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
            placeholder="250 788 841 494"
            {...register("info")}
          />
          <p className="mt-2 text-sm text-red-600">
            {errors.info?.message}
          </p>
        </div>

        <div className="mt-3">
          <label htmlFor="img">Image</label>
          <input
            type="text"
            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
            placeholder="250 788 841 494"
            {...register("img")}
          />
          <p className="mt-2 text-sm text-red-600">
            {errors.img?.message}
          </p>
        </div>

        <div className="mt-3 text-right">
          <div className="inline-flex items-end">
            <button
              type="submit"
              className="flex items-center bg-indigo-600 text-white hover:bg-purple-500 p-2 rounded text-sm w-auto"
              // onClick={onSubmitHandle}
            >
              <BsFillCursorFill />
              <span>&nbsp;Submit</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default NewProduct;
