import * as React from "react";
import {
  BsFillPenFill,
  BsFillTrashFill,
  BsFillCalendar3WeekFill,
  BsReplyAllFill,
  BsShareFill,
  BsMailbox2,
  BsTelephoneFill,
  BsFilePerson,
} from "react-icons/bs";
import Product from "../model/Product";
import { useAppDispatch } from "../app/hooks";
import { removeProduct } from "../app/productSlice";

interface ProductProps {
  product: Product;
  onProductUpdate: (id: string) => void;
}

const ProductInfo: React.FC<ProductProps> = (props) => {
  const dispatch = useAppDispatch();
  const product = props.product;

  const setUpdatePage = (id: string) => {
    props.onProductUpdate(id);
  };

  return (
    <a href={product.link}>
      <div className="bg-white-500">
        <div className="flex flex-col pb-2 overflow-auto">
          <div
            className="relative flex flex-col items-start p-4 mt-3 bg-white rounded-lg cursor-pointer bg-opacity-90 group hover:bg-opacity-100 card-height"
            draggable="true"
          >
            <button
              className="absolute top-0 right-0  items-center justify-center hidden w-5 h-5 mt-3 mr-2 text-gray-500 rounded hover:bg-gray-200 hover:text-gray-700 group-hover:flex"
              onClick={() => setUpdatePage(product.id)}
            >
              {/* <BsFillPenFill /> */}
            </button>
            <button
              className="absolute top-7 right-0  items-center justify-center hidden w-5 h-5 mt-3 mr-2 text-gray-500 rounded hover:bg-gray-200 hover:text-gray-700 group-hover:flex"
              onClick={() => dispatch(removeProduct({ id: product.id }))}
            >
              {/* <BsFillTrashFill /> */}
            </button>

            {/* <div className="flex items-center w-full mt-3 text-xs font-medium text-gray-400">
              <img
                className="w-6 h-6 ml-auto rounded-full"
                src="https://i.pinimg.com/280x280_RS/ab/a2/8e/aba28eb29f66aab5f24db128a0232f3f.jpg"
                alt=""
              ></img>
              <i className="fa-solid fa-file-lines main-icon"></i>
            </div> */}

            <div className="rounded-md pl-6 text-sm font-medium text-gray-800">
              <div className="flex items-center w-full ">
                <div className="flex items-center">
                <span className="ml-1 leading-none card-heading" dangerouslySetInnerHTML={{__html: product.img}}></span>
                </div>
                <div className="flex items-center">
                  {/* <BsFilePerson /> */}
                  <span className="ml-1 leading-none card-heading">{product.name}</span>
                </div>
              </div>

              <div className="flex items-center w-full mt-3 text-xs font-medium text-gray-400">
                <div className="flex items-center">
                  {/* <BsTelephoneFill /> */}
                  <span className="leading-none">{product.info}</span>
                </div>
              </div>

              {/* <div className="flex items-center w-full mt-3 text-xs font-medium text-gray-400">
                <div className="flex items-center">
                  <BsMailbox2 />
                  <span className="ml-1 leading-none">{product.email}</span>
                </div>
              </div> */}
            </div>
            
          </div>
        </div>
      </div>
    </a>
  );
};

export default ProductInfo;
