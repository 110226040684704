import React, { useState } from "react";
import Sidebar from "../components/Sidebar";

const TestPage = () => {

    const [collapsed, setCollapsed] = useState(false);
    const [image, setImage] = useState(false);
    const [toggled, setToggled] = useState(false);
  
    const handleCollapsedChange = () => {
      setCollapsed(!collapsed);
    };
  
    const handleImageChange = (checked) => {
      setImage(checked);
    };
  
    const handleToggleSidebar = (value) => {
      setToggled(value);
    };

    return(
        <>
            <div className={`app ${toggled ? "toggled" : ""}`}>
                <Sidebar
                    image={image}
                    collapsed={collapsed}
                    toggled={toggled}
                    handleToggleSidebar={handleToggleSidebar}
                    handleCollapsedChange={handleCollapsedChange}
                />
            </div>
        </>
    );
}

export default TestPage;
