import React, { useEffect, useState } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const TextEditor = (props) => {

    const [value, setValue] = useState("");
    const newValue = props.value;
    let [loading, setLoading] = useState(true);
    let [color, setColor] = useState("#000");

    // useEffect(() => {
    //     setValue(newValue);
    // });

    return (
        <>
            <ReactQuill theme="snow" value={props.value} onChange={props.handleTextChange} />
        </>
    );
}

export default TextEditor;