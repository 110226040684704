import { useState, useEffect } from "react";

import { useAppSelector } from "../app/hooks";
import Product from "../model/Product";

import ProductList from "../components/productList";
import NewProduct from "../components/NewProduct";
import DialogBox from "../components/DialogBox";
import NavBar from "../components/NavBar";
import FooterContent from "../components/FooterContent";
import Sidebar from "../components/Sidebar";

const ProductPage = (props) => {
  let [open, setOpen] = useState(false);
  const getProductList = useAppSelector((state) => state.product.productList);

  const [searchTerm, setSearchTerm] = useState("");
  const [productListData, setProductListData] = useState([Product]);

  const [collapsed, setCollapsed] = useState(false);
  const [image, setImage] = useState(false);
  const [toggled, setToggled] = useState(false);

  const handleCollapsedChange = () => {
    setCollapsed(!collapsed);
  };

  const handleImageChange = (checked) => {
    setImage(checked);
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  useEffect(() => {
    setProductListData(getProductList);
    const filteredData = getProductList.filter((item) =>
      item.name.toLowerCase().includes(searchTerm)
    );
    setProductListData(filteredData);
  }, [getProductList, searchTerm]);
  const DialogHandle = () => {
    setOpen((current) => !current);
  };
  return (
    <>
      <NavBar/>
      <section className="hero-container pb-5 bg-static">
        <article className="">
          <div className="flex flex-col w-screen h-screen overflow-auto text-gray-700 pt-5">
            <div className="m-5 position-relative ml-5">
              <input
                className="flex items-center h-10 px-4 ml-10 text-sm w-1/3  bg-gray-200 rounded-full focus:outline-none focus:ring"
                type="search"
                placeholder="Search product …"
                onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
              />
              <span className="flex items-center h-10 px-4 ml-10 text-sm w-1/3 text-white">(Showing {productListData?.length} of {getProductList?.length} services )</span>
            </div>
            <ProductList products={productListData} />
            {open && (
              <DialogBox open={open} OnDialogHandle={DialogHandle}>
                <NewProduct id={""} />
              </DialogBox>
            )}
          </div>
        </article>
      
      </section>
      {/* <FooterContent/> */}
    </>
  );
};

export default ProductPage;
