import React from "react";

const Message = ({ message, formatMessageContent }) => {

  const getRole = (role) => {
    if(role === "user"){
      return "user";
    }
    
    if(role === "assistant") {
      return "assistant";
    }

    if(role === "system") {
      return "hidden";
    }
  }

  return (
    <div
      className={`message ${
        getRole(message.role)
      }`}
      dangerouslySetInnerHTML={{
        __html: formatMessageContent(message.content),
      }}
    />
  );
};

export default Message;
