import { useState } from "react";
import ProductInfo from "./productInfo";
import Product from "../model/Product";
import NewProduct from "./NewProduct";
import DialogBox from "./DialogBox";

interface ProductProps {
  products: Product[] | undefined;
}
const ProductList: React.FC<ProductProps> = ({ products }) => {
  let [open, setOpen] = useState(false);
  let [id, setID] = useState("");

  const ProductUpdate = (id: string) => {
    setID(id);
    setOpen(true);
  };

  const DialogHandle = () => {
    setOpen((current) => !current);
  };
  return (
    <div className="flex flex-col mx-20 overflow-auto">
        <div id="card-holder" className="grid grid-cols-1 md:grid-cols-4 gap-4">
          {products &&
            products.map((product) => (
              <ProductInfo
                key={product.id}
                product={product}
                onProductUpdate={ProductUpdate}
              />
            ))}

          {open && (
            <DialogBox open={open} OnDialogHandle={DialogHandle}>
              <NewProduct id={id} />
            </DialogBox>
          )}
        </div>
    </div>
  );
};

export default ProductList;
