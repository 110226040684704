import React from "react";
import NavBar from "../components/NavBar";
import FooterContent from "../components/FooterContent";

const ErrorPage = () => {

    return (
        <>
            <NavBar/>
            <section class="hero-container pb-5">
                <article class="container error-template">
                    
                    <div class="error-block">
                        404
                        <div class="error-item">404</div>
                        <div class="error-item">404</div>
                    </div>
                        
                    <h2 class="section-title">Page Not Found</h2>
                    <div class="error-details">
                        Sorry, an error has occured, Requested page not found <br/>or is temporarily unavailable!
                    </div>
                    <div class="error-actions">
                        <a href="#" class="btn btn-primary btn-lg">Take Me Home </a>
                    </div>
                </article>
            </section>
            {/* <FooterContent/> */}
        </>
    );
}

export default ErrorPage;