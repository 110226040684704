import React, { useState } from "react";
import NavBar from "../components/NavBar";
import FooterContent from "../components/FooterContent";
import TerminalController from "../components/Terminal";
import LRM1 from "../images/LRM-1.svg";
import LRM2 from "../images/LRM-2.svg";
import LRM3 from "../images/LRM-3.svg";
import Reliable from "../images/icon-reliable.svg";
import Subscribe from "../images/icon-subscribe.svg";
import UE from "../images/icon-ue.svg";

const IndexPage = () => {

    return (
        <>
            <NavBar/>
            {/* <!--Banner--> */}
            <section className="hero-container pb-5">
                <article className="text-center wrapper pb-5">
                    <h1 className="hero-title">
                        Meet your new AI Legal Co-Pilot
                    </h1>
                    <div className="hero-tag">
                        Jupitice AI legal Co-Pilot harnesses the power of AI to deliver the result. <br/>Explore how legal Co-Pilot can assist you.
                    </div>
                    <div className="mt-5 pb-5">            
                        <a className="btn btn-primary"
                            href="/products" target="_self">Browse All Services</a>
                    </div>

                    <div className="text-center pb-5">
                        {/* <img src={sayaGif} alt="" /> */}
                        {/* <!-- <iframe allow="autoplay; fullscreen; picture-in-picture; camera; microphone; display-capture" allowfullscreen="" allowtransparency="true" aria-label="Video" class="vidyard-iframe-c3KpWUWKMQYW7vAdqxztKP" frameborder="0" height="100%" width="100%" scrolling="no" src="https://play.vidyard.com/c3KpWUWKMQYW7vAdqxztKP?disable_popouts=1&amp;disable_analytics=0&amp;preload=auto&amp;disable_larger_player=true&amp;controller=hubs&amp;action=show&amp;type=inline&amp;v=4.2.21" title="Video" style="opacity: 1; background-color: transparent; position: absolute; top: 0px; left: 0px;"></iframe> --> */}
                        <h1 className="hero-title">
                            Legal Consultation
                        </h1>

                        <div className="hero-tag">
                            Guiding You Through Legal Complexities. Trustworthy Legal Consultation at Your Service.
                        </div>
                    </div>
                </article>

                <div className="pb-5 terminal-container">
                    <TerminalController />
                </div>
            </section>

            {/* <!--Introduction block--> */}
            <section className="intro-container pb-5">
                <article className="wrapper text-center">
                    <h2 className="section-title">
                        Introducing Legal Co-Pilot, the world’s first reliable AI legal assistant, powered by GPT-4
                    </h2>
                    <p className="tagline mt-4 mb-5">Let Legal Co-Pilot handle an array of critical tasks, for superior work at superhuman speed—giving you more time to better serve your clients and grow your business.</p>
                    <button className="btn btn-outline-primary" type="submit">Meet Legal Co-Pilot</button>
                </article>

                <article className="container specs">
                    <div className="row pb-5 gx-5">
                        <div className="col-12 col-lg-6 align-self-center">
                            <h3 className="section-title">
                                Fast-track legal research<br/>and deposition prep
                            </h3>
                            <p className="mt-4 mb-lg-5">Enter an issue and relevant information such as jurisdiction. Get a complete answer with supporting sources in seconds. Provide information about your deposition and get a thorough outline in no time.</p>
                            
                            <a className="atom-link" href="/" target="_self" data-type="url">
                                <span className="atom-text">Explore litigation skills</span>
                                <span className="atom-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="16" viewBox="0 0 40 16" fill="none">
                                        <path
                                            d="M39.7071 8.70711C40.0976 8.31659 40.0976 7.68342 39.7071 7.2929L33.3431 0.928935C32.9526 0.538411 32.3195 0.538411 31.9289 0.928935C31.5384 1.31946 31.5384 1.95262 31.9289 2.34315L37.5858 8L31.9289 13.6569C31.5384 14.0474 31.5384 14.6805 31.9289 15.0711C32.3195 15.4616 32.9526 15.4616 33.3431 15.0711L39.7071 8.70711ZM-8.74228e-08 9L39 9L39 7L8.74228e-08 7L-8.74228e-08 9Z"
                                            fill="#0461CF"></path>
                                    </svg>
                                </span>
                            </a>
                        </div>
                        <div className="col-12 col-lg-6">
                            <img src={LRM1} className="mt-sm-5"/>
                        </div>
                    </div>

                    <div className="row mt-5 pb-5 gx-5">                    
                        <div className="col-12 col-lg-6">
                            <img src={LRM2} />
                        </div>
                        <div className="col-12 col-lg-6 align-self-center">
                            <h3 className="section-title mt-sm-5">
                                Don't miss a detail during document review
                            </h3>
                            <p className="mt-4 mb-lg-5">Legal Co-Pilot identifies critical documents in contract databases and key information in discovery—increasing precision and recall and lowering costs.</p>
                        </div>
                    </div>

                    <div className="row mt-sm-3 gx-5">
                        <div className="col-12 col-lg-6 align-self-center pb-sm-5">
                            <h3 className="section-title">
                                Automate contract revision
                            </h3>
                            <p className="mt-4 mb-lg-5">Upload contracts and the policies they should comply with. Legal Co-Pilot identifies relevant clauses, conflicts, and risks, then recommends revisions.</p>
                            
                            <a className="atom-link" href="/" target="_self" data-type="url">
                                <span className="atom-text">Explore transactional skills</span>
                                <span className="atom-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="16" viewBox="0 0 40 16" fill="none">
                                        <path
                                            d="M39.7071 8.70711C40.0976 8.31659 40.0976 7.68342 39.7071 7.2929L33.3431 0.928935C32.9526 0.538411 32.3195 0.538411 31.9289 0.928935C31.5384 1.31946 31.5384 1.95262 31.9289 2.34315L37.5858 8L31.9289 13.6569C31.5384 14.0474 31.5384 14.6805 31.9289 15.0711C32.3195 15.4616 32.9526 15.4616 33.3431 15.0711L39.7071 8.70711ZM-8.74228e-08 9L39 9L39 7L8.74228e-08 7L-8.74228e-08 9Z"
                                            fill="#0461CF"></path>
                                    </svg>
                                </span>
                            </a>
                        </div>
                        <div className="col-12 col-lg-6">
                            <img src={LRM3} />
                        </div>
                    </div>

                </article>
            </section>
            

            {/* <!--pioneers in AI--> */}
            <section className="ksp-container mt-5">
                <article className="container text-center pt-5">
                    <h2 className="section-title">The pioneers in AI for the law</h2>
                    <p className="tagline mt-4 pb-3">
                        Legal Co-Pilot technology empowers more lawyers to provide higher-quality, more <br/>efficient, and more affordable representation, to more people.
                    </p>
                    <div className="row pt-5 ksp-info">
                        <div className="col-12 col-lg-4 col-xl-4 col-xxl-4 mb-sm-5 text-center">
                            <img src={UE} alt=""/>
                            <h3 className="section-title">Unparalleled expertise</h3>
                            <p>Since 2013 our attorneys (who have a combined 110+ years of experience), AI and machine learning experts, and product and design leaders have launched 6 products, used by more than 10,000 firms spanning all sizes and practice areas.</p>
                        </div>
                        <div className="col-12 col-lg-4 col-xl-4 col-xxl-4 mb-sm-5 text-center">
                            <img src={Subscribe} alt=""/>
                            <h3 className="section-title">Substance, not hype</h3>
                            <p>Because our customers trust us with their clients’ well-being, we thoroughly vet every new technology before launching solutions. “Generative AI” might seem new, but we’ve been working with large language models since 2018, not 2022.</p>
                        </div>
                        <div className="col-12 col-lg-4 col-xl-4 col-xxl-4 mb-sm-5 text-center">
                            <img src={Reliable} alt=""/>
                            <h3 className="section-title">Reliable rigor</h3>
                            <p>Our top priority is trust and safety. Legal Co-Pilot expert attorneys and prompt engineers have spent thousands of hours and several months training Legal Co-Pilot filtering, ranking, and scoring results.</p>
                        </div>
                    </div>

                    <div className="py-4">
                        <button className="btn btn-primary" type="submit">Why Legal Co-Pilot?</button>
                    </div>
                </article>
            </section>

            {/* <FooterContent /> */}

        </>
    );
}

export default IndexPage;