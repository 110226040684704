import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import TextEditor from "../components/textEditor";
import NavBar from "../components/NavBar";
import axios from "axios";
import FooterContent from "../components/FooterContent";
import FadeLoader from "react-spinners/FadeLoader";

const SummaryPage = (props) => {

    const [text, setText] = useState("");
    const [textInput, setTextInput] = useState("");
    const [loader, setLoader] = useState("none");
    let [color, setColor] = useState("#000");

    const handleSubmit = async(e) => {

        setLoader("flex");

        e.preventDefault();

        let formData = new FormData();    //formdata object
        formData.append('text', JSON.stringify(textInput));

        const config = {     
            headers: { 'content-type': 'multipart/form-data' }
        }

        try {
            const response = await axios.post(`https://ai-gpt-test.jupitice.com/api/summarize/`, formData, config);
            setText(response.data.summary);
            setLoader("none");
        } catch (error) {
            console.error("Error fetching chats:", error);
            setText("Something happend at the server.");
            setLoader("none");
        }
      

    }

    const hanldeChange = (event) => {

        event.preventDefault();
        setTextInput(event.target.value)

    }

    const handleTextChange = (text) => {

        setText(text);
    }

    return (
        <>
        <NavBar/>
            <section className="hero-container pb-5 pt-5 overflow-hidden">
                <div className="container mt-5 mb-5">
                    <div className="row pt-3">
                        <div className="col-lg-6">
                            <div className="card widget">
                                <div className="card-body">
                                    <div className="card-header">
                                        <span className="icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" height="0.8em" viewBox="0 0 448 512">
                                                <path d="M96 0C43 0 0 43 0 96V416c0 53 43 96 96 96H384h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V384c17.7 0 32-14.3 32-32V32c0-17.7-14.3-32-32-32H384 96zm0 384H352v64H96c-17.7 0-32-14.3-32-32s14.3-32 32-32zm32-240c0-8.8 7.2-16 16-16H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16zm16 48H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
                                            </svg>
                                        </span>
                                        <span className="text">Text Summarization</span>
                                    </div>
                                   
                                    <div className="iconText grey">
                                        Summmarize any text in a short and easy to understand concise way
                                    </div>

                                    <div className="iconText">
                                        <div className="question">What would you like to summarize? <span className="asstric">*</span></div>
                                    </div>
                                    <form className="form-group" onSubmit={handleSubmit}>
                                        {/* <input className="form-control" type="text" placeholder="Type in your text here.."/> */}
                                        <textarea className="form-control rounded-2 z-depth-1 grey" rows={5} cols={50} placeholder="Type in your text here.." onChange={hanldeChange}></textarea>

                                        <button type="submit" className="btn btn-primary">Generate Text</button>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="card widget">
                                <div className="card-body">
                                    <div className="card-header">
                                        <span className="icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" height="0.8em" viewBox="0 0 448 512">
                                                <path d="M96 0C43 0 0 43 0 96V416c0 53 43 96 96 96H384h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V384c17.7 0 32-14.3 32-32V32c0-17.7-14.3-32-32-32H384 96zm0 384H352v64H96c-17.7 0-32-14.3-32-32s14.3-32 32-32zm32-240c0-8.8 7.2-16 16-16H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16zm16 48H336c8.8 0 16 7.2 16 16s-7.2 16-16 16H144c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
                                            </svg>
                                        </span>
                                        <span className="text">Optimised Summary</span>
                                    </div>
                                   
                                    <div className="iconText grey">
                                        Our smart editor allows you to easily customize your content summaries. Change the order of sentences, add, edit or remove information to match your style.
                                    </div>

                                    <div id="overlay" style={{display: loader}}>
                                        <div class="w-100 d-flex justify-content-center align-items-center">
                                            <div className="sweet-loading">
                                                <FadeLoader
                                                    color={color}
                                                    loading={true}
                                                    size={20}
                                                    aria-label="Loading Spinner"
                                                    data-testid="loader"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <TextEditor value={text} handleTextChange={handleTextChange} />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            
            {/* <FooterContent /> */}
        </>
    );
}

export default SummaryPage;