import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import Product from "../model/Product";
import { v4 as uuidv4 } from "uuid";

type initialStateType = {
  productList: Product[];
};

const productList: Product[] = [
  {
    id: uuidv4(),
    name: "Translation",
    link: "/translation?Translate_Text,Translation",
    info: "Bridging Language Barriers. Seamless Communication. Translation Product: Unlocking Global Connections.",
    img: "",
  },
  {
    id: uuidv4(),
    name: "Summarization",
    link: "/summarization",
    info: "Experience the efficiency and productivity boost that our Summarizer brings to the table. Simplify complex information, make informed decisions, and stay ahead of the game in today's information-driven world.",
    img: "",
  },
  {
    id: uuidv4(),
    name: "Offense Analyzer  ",
    link: "/offense-identifier?Offense_Analyzer,Identification",
    info: "Empowering Justice. Unveiling Truth. Crime Identifier: Unmasking the Culprits.",
    img: "",
  },
  {
    id: uuidv4(),
    name: "Document Generator",
    link: "/document-generator?Document_Generator,Document",
    info: "Efficiently Generate Legally Binding Documents with Ease",
    img: "",
  },
  {
    id: uuidv4(),
    name: "Case Similarity Identifier",
    link: "/case-sim-identifier?Case_Similarity_Identifier,Identification",
    info: "Discover Legal Connections: History-Based Case Similarity Identifier",
    img: "<img src='https://etimg.etb2bimg.com/photo/84464304.cms' height='50px' width='50px'/>",
  },
  {
    id: uuidv4(),
    name: "Complaint Analyzer",
    link: "/complaint-analyzer?Complaint_Analyzer,Analysis",
    info: "Uncover Insights: Complaint Analyzer - Decoding Complaints with Precision",
    img: "",
  },
  {
    id: uuidv4(),
    name: "Legal Entity Identifier",
    link: "/ler?Legal_Entity_Identifier,Entities_&_Relationships",
    info: "Securely Identify and Verify Legal Entities with our LEI Solutions",
    img: "<img src='https://etimg.etb2bimg.com/photo/84464304.cms' height='50px' width='50px'/>",
  },
  {
    id: uuidv4(),
    name: "Cause List Generation",
    link: "/case-list-generation?Cause_List_Generation,Result",
    info: "Condensed Insight, Comprehensive Understanding: Your Case Summarized",
    img: "<img src='https://consumerhelpline.gov.in/images/emblem-gold.png' height='30px' width='30px'/>",
  },
  {
    id: uuidv4(),
    name: "Case Record",
    link: "/case-record?Case_Record,Result",
    info: "Condensed Insight, Comprehensive Understanding: Your Case Summarized",
    img: "<img src='https://consumerhelpline.gov.in/images/emblem-gold.png' height='30px' width='30px'/>",
  },
  {
    id: uuidv4(),
    name: "Case Diagnostics",
    link: "/case-diagnostics?Case_Diagnostics,Result",
    info: "Condensed Insight, Comprehensive Understanding: Your Case Summarized",
    img: "<img src='https://consumerhelpline.gov.in/images/emblem-gold.png' height='30px' width='30px'/>",
  },
  {
    id: uuidv4(),
    name: "Argument Miner",
    link: "/argument-miner?Argument_Miner,Result",
    info: "Condensed Insight, Comprehensive Understanding: Your Case Summarized",
    img: "<img src='https://consumerhelpline.gov.in/images/emblem-gold.png' height='30px' width='30px'/>",
  },
  {
    id: uuidv4(),
    name: "Judgement Finder",
    link: "/judgement-finder?Judgement_Finder,Result",
    info: "Condensed Insight, Comprehensive Understanding: Your Case Summarized",
    img: "<img src='https://consumerhelpline.gov.in/images/emblem-gold.png' height='30px' width='30px'/>",
  },
  {
    id: uuidv4(),
    name: "Bench Constitutions",
    link: "/bench-constitutions?Bench_Constitutions,Result",
    info: "Condensed Insight, Comprehensive Understanding: Your Case Summarized",
    img: "<img src='https://consumerhelpline.gov.in/images/emblem-gold.png' height='30px' width='30px'/>",
  },
  {
    id: uuidv4(),
    name: "Police Complaint Generator",
    link: "/complaint-generator?Police_Complaint_Generator,Result",
    info: "Condensed Insight, Comprehensive Understanding: Your Case Summarized",
    img: "<img src='https://consumerhelpline.gov.in/images/emblem-gold.png' height='30px' width='30px'/>",
  },
  {
    id: uuidv4(),
    name: "Reply a Petition",
    link: "/reply-petition?Reply_a_petition,Result",
    info: "Condensed Insight, Comprehensive Understanding: Your Case Summarized",
    img: "<img src='https://consumerhelpline.gov.in/images/emblem-gold.png' height='30px' width='30px'/>",
  },
  {
    id: uuidv4(),
    name: "Draft a Complaint",
    link: "/draft-complaint?Draft_a_Complaint,Complaint_Generator",
    info: "Raise Your Voice. Demand Justice. Complaint Drafting: Empowering Your Grievances.",
    img: "",
  },
  {
    id: uuidv4(),
    name: "Document Redlining",
    link: "/document-redlining?Document_Redlining,Result",
    info: "Raise Your Voice. Demand Justice. Document Redlining: Empowering Your Differences.",
    img: "",
  },
  {
    id: uuidv4(),
    name: "Document Review",
    link: "/document-review?Document_Review,Review",
    info: "Efficient, Accurate, and Reliable Document Review Services",
    img: "",
  },
  {
    id: uuidv4(),
    name: "Document QnA",
    link: "/document-qna?Document_QnA",
    info: "Unlocking Knowledge, Empowering Decision-Making: Document Q&A Services",
    img: "<img src='https://etimg.etb2bimg.com/photo/84464304.cms' height='50px' width='50px'/>",
  },
  {
    id: uuidv4(),
    name: "Case QnA",
    link: "/case-qna?Case_QnA",
    info: "Insightful Solutions, Informed Decisions: Case Q&A Services",
    img: "<img src='https://etimg.etb2bimg.com/photo/84464304.cms' height='50px' width='50px'/>",
  },
  {
    id: uuidv4(),
    name: "Case Law Finder",
    link: "/case-law-finder?Case_Law_Finder,Case_Law",
    info: "Unlocking Legal Knowledge: Case Law Finder Services",
    img: "",
  },
  {
    id: uuidv4(),
    name: "Bi-lingual Information Extractor",
    link: "/bi-info-extractor?Bi-lingual_Information_Extractor,Extraction",
    info: "Unlock the Power of Language: Bilingual Information Extractor - Extracting Insights Across Languages",
    img: "<img src='https://etimg.etb2bimg.com/photo/84464304.cms' height='50px' width='50px'/>",
  },
  {
    id: uuidv4(),
    name: "Notice Generation",
    link: "/notice-generator?Notice_Generation,Draft",
    info: "Effortless Notice Generation: Streamline Communication with our Notice Generation Service",
    img: "<img src='https://etimg.etb2bimg.com/photo/84464304.cms' height='50px' width='50px'/>",
  },
  {
    id: uuidv4(),
    name: "Clause Generator",
    link: "/clause-generator?Clause_Generator,Clause",
    info: "Effortlessly Generate Tailored Contract Clauses for Your Legal Documents",
    img: "",
  },
  {
    id: uuidv4(),
    name: "Judgement Builder",
    link: "/judgement-builder?Judgement_Builder,Judgement",
    info: "Empowering Legal Decisions: Build Strong Judgements with our Judgement Builder Service",
    img: "",
  },
  {
    id: uuidv4(),
    name: "Clause Analysis",
    link: "/clause-analysis?Clause_Analysis,Analysis",
    info: "Unlocking Legal Clarity: Comprehensive Clause Analysis for Informed Decision-Making",
    img: "",
  },
  {
    id: uuidv4(),
    name: "Legal Research Writing",
    link: "/legal-research-writing?Legal_Research_Writing,Writing",
    info: "Empower Your Legal Writing with Comprehensive Legal Research",
    img: "",
  },
  {
    id: uuidv4(),
    name: "Legal Analysis",
    link: "/legal-analysis?Legal_Analysis,Analysis",
    info: "Insightful Legal Analysis: Empowering You with Informed Legal Strategies",
    img: "",
  },
  {
    id: uuidv4(),
    name: "Research Laws",
    link: "/law-research?Research_Laws,Your_Output",
    info: "Uncovering Legal Insights: Unlocking the Power of Knowledge",
    img: "",
  },
  {
    id: uuidv4(),
    name: "Explain a Clause",
    link: "/explain-clause?Explain_a_Clause,Explaination",
    info: "Decoding Legal Language: Simplifying Clause Analysis",
    img: "",
  },
  {
    id: uuidv4(),
    name: "Legal Brief Drafting",
    link: "/brief-drafting?Legal_Brief_Drafting,Draft",
    info: "Powerful Persuasion, Precise Precision: Crafting Winning Legal Briefs",
    img: "",
  },
  {
    id: uuidv4(),
    name: "Case Summarized Record",
    link: "/case-summarized-record?Case_Summarized_Record,Summary",
    info: "Condensed Insight, Comprehensive Understanding: Your Case Summarized",
    img: "<img src='https://etimg.etb2bimg.com/photo/84464304.cms' height='50px' width='50px'/>",
  },
];

const initialState: initialStateType = {
  productList,
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    addProduct: (state, action: PayloadAction<Product>) => {
      state.productList.push(action.payload);
    },
    updateProduct: (state, action: PayloadAction<Product>) => {
      const {
        payload: { id, name, link, info, img },
      } = action;

      state.productList = state.productList.map((product) =>
        product.id === id ? { ...product, name, link, info, img } : product
      );
    },
    removeProduct: (state, action: PayloadAction<{ id: string }>) => {
      state.productList = state.productList.filter(
        (product) => product.id !== action.payload.id
      );
    },
  },
});

export const { addProduct, updateProduct, removeProduct } =
  productSlice.actions;
export const getProductList = (state: RootState) => state.product.productList;

export default productSlice.reducer;
