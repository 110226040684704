import React, { useState } from 'react';
import Terminal, { ColorMode, TerminalInput, TerminalOutput } from 'react-terminal-ui';
import axios from "axios";

const TerminalController = (props) => {

  var terminalLines = [<>
    <TerminalOutput>Hi, I'm your Legal Co-Pilot. I can assist you in your legal queries.</TerminalOutput>
    <TerminalOutput>Ask me anything!</TerminalOutput>
  </>]

  const [terminalData, setTerminalData] = useState([terminalLines]);
  const [terminalLines1, setterminalLines1] = useState([terminalLines]);

  const terminalQuery = async(input) => {

      let formData = new FormData();    //formdata object
      formData.append('text', JSON.stringify(input));

      terminalLines.push(<TerminalInput>{input}</TerminalInput>);
      // setTerminalData(terminalLines);

      const config = {     
          headers: { 'content-type': 'multipart/form-data' }
      }

      try {
          const response = await axios.post(`https://ai-gpt-test.jupitice.com/api/generic/`, formData, config);
          terminalLines.push(<TerminalOutput>{"Here's what we got ...   " + response.data.summary}</TerminalOutput>)
      } catch (error) {
          console.error("Error fetching chats:", error);
          terminalLines.push("Something happend at the server.");
      }

      setTerminalData(terminalLines);
      console.log(terminalLines1);
      setterminalLines1(terminalData);
  }

  // Terminal has 100% width by default so it should usually be wrapped in a container div
  return (
      <Terminal 
        height='fit-content' 
        name='' 
        prompt="$" 
        colorMode={ ColorMode.Dark }  
        onInput={ terminalInput => terminalQuery(terminalInput) }
      >
        { terminalData }
      </Terminal>
  )
};

export default TerminalController;